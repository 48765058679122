import { NgRedux } from '@angular-redux/store';
import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EmStateAction, IAppStateEm } from '@theia-cc/em/state';
import { cleanEmptyProperties } from '@theia-cc/shared/helpers';
import { ShareLinkService, UserInfoService } from '@theia-cc/shared/services';

@Injectable()
export class ParamsToCollectedDataResolverEm implements Resolve<void> {
  constructor(
    private stepActions: EmStateAction,
    private userInfoService: UserInfoService,
    private shareLinkService: ShareLinkService,
    private store: NgRedux<IAppStateEm>,
    private location: Location,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { queryParams } = route;
    this.userInfoService.storeAdditionalAddresses(queryParams);
    const { collectedData } = this.shareLinkService.splitQueryParams(queryParams);

    if (Object.keys(cleanEmptyProperties(collectedData)).length) {
      this.stepActions.setConfigSettings(cleanEmptyProperties(collectedData, 'unknown'));

      setTimeout(() => {
        this.location.replaceState(
          this.shareLinkService.generateQueryParamsLink(
            this.shareLinkService.urlLinkQueryParamsSelector(this.store.getState()),
            this.document.location.pathname
          )
        );
      });
    }
  }
}
